import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import LandingPage from './pages/LandingPage';
import CustomNavbar from './components/CustomNavbar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FoundingPage from './pages/FoundingPage';

function App() {
    
  return (
    <div className="App">
      <CustomNavbar/>
      <Routes>
        <Route path="/" element={<LandingPage/>} />
        <Route path="/about" element={<FoundingPage/>} />
      </Routes>
      <ToastContainer/>
    </div>
  );
}

export default App;
