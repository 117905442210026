import { Link } from "react-scroll";
import { Element } from "react-scroll";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import FooterComponent from "../components/FooterComponent";
import LoadingModal from "../components/LoadingModal";
import "./LandingPage.css";
import GettingResponse from "../components/GettingResponse";

function LandingPage() {
  const [Hospital, SetHospital] = useState("");
  const [Contact, SetContact] = useState("");
  const [Email, SetEmail] = useState("");
  const [Phone, SetPhone] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [customAttributes, setCustomAttributes] = useState([]);

  const [companyName, setCompanyName] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerAge, setCustomerAge] = useState("");
  const [customerInteraction, setCustomerInteraction] = useState("never");
  const [generatedEmail, setGeneratedEmail] = useState(null);
  const [fetchingResponse, setFetchingResponse] = useState(false);
  const [marketerName, setMarketerName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");

  const handleAddAttribute = () => {
    if (customAttributes.length < 3) {
      setCustomAttributes([
        ...customAttributes,
        { attribute: "", description: "" },
      ]);
    }
  };

  const handleGenerateEmail = async () => {
    // Construct the data to send in the POST request
    if (
      companyDescription === "" ||
      companyName === "" ||
      customerName === "" ||
      customerAge === ""
    ) {
      console.log("here");
      toast.error("Please fill out all fields");
      return;
    }
    setFetchingResponse(true);
    const formData = {
      marketerName,
      companyWebsite,
      companyName,
      companyDescription,
      customerName,
      customerAge,
      customerInteraction,
      customAttributes,
    };

    // Make a POST request to a Flask endpoint with formData using Axios
    await axios
      .post("/generate-email", formData)
      .then((response) => {
        // Handle the response from the server
        console.log(response.data.email);
        setGeneratedEmail(response.data.email);
        setFetchingResponse(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setFetchingResponse(false);
      });
  };

  const handleAttributeChange = (index, attributeName) => (e) => {
    const updatedAttributes = [...customAttributes];
    updatedAttributes[index][attributeName] = e.target.value;
    setCustomAttributes(updatedAttributes);
  };
  useEffect(() => {
    function preloadImages(imageSources) {
      const promises = imageSources.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      });
      return Promise.all(promises);
    }
    try {
      const imageSources = [];
      preloadImages(imageSources).then(() => {
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  });
  if (loading) {
    return <LoadingModal />;
  }

  const handleSubmit = () => {};

  const submitSalesEmail = async (e) => {
    e.preventDefault();
    await axios
      .post("/api/sales", {
        hospital: Hospital,
        name: Contact,
        email: Email,
        phone: Phone,
      })
      .then((res) => {
        toast.success(
          "Thank you for your interest! We will be in touch shortly."
        );
        SetHospital("");
        SetContact("");
        SetEmail("");
        SetPhone("");
      })
      .catch((err) => {
        toast.error(
          "There was an error submitting your request. Please try again later."
        );
      });
  };

  const handleDeleteAttribute = (index) => () => {
    const updatedAttributes = [...customAttributes];
    updatedAttributes.splice(index, 1);
    setCustomAttributes(updatedAttributes);
  };

  return (
    <>
      <div className="bg-[#F2F7FF]">
        <main className="flex flex-col lg:flex-row items-center md:px-[80px] p-[50px]  bg-[#F2F7FF]">
          <div class="pt-10">
            {/* main text content */}
            <h1
              class="pt-10 font-poppins text-4xl md:text-5xl  font-bold text-black"
              style={{ fontFamily: "Poppins" }}
            >
              Experience <span className="text-[#1F2FBC]">AI-powered</span>{" "}
              Marketing Agents
            </h1>
            <h3 class="pr-10 pt-10 pb-10 font-poppins text-xl text-[#6C87AE]">
              Our platform syncs with your data in realtime, crafting
              indvidualized emails and content  each one of your customers and sending the
              right message at the right time.
            </h3>
            <div class="grid grid-flow-col mb-5">
              <div class="flex justify-start items-center">
                <form
                  className="mt-6 flex max-w-md gap-x-4"
                  onSubmit={handleSubmit}
                >
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="min-w-0 flex-auto rounded-md focus:ring-2 focus:ring-transparent  focus:outline-none pl-2"
                    placeholder="Enter your email"
                  />
                  <button
                    type="submit"
                    className="bg-[#1F2FBC] rounded-md px-4 py-2 font-medium text-white shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2"
                  >
                    Join Waitlist
                  </button>
                </form>
              </div>
            </div>
          </div>
          <img
            src="image2.png"
            alt="StethoAI UI"
            class="max-w-full min-w-[200px] md:min-w-[300px] lg:min-w-[400px]"
            width="100%"
            height="auto"
          />
        </main>

        <svg
          className="absoulte z-0"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#FFFFFF"
            fill-opacity="1"
            d="M0,96L48,85.3C96,75,192,53,288,90.7C384,128,480,224,576,234.7C672,245,768,171,864,133.3C960,96,1056,96,1152,122.7C1248,149,1344,203,1392,229.3L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>

      {/* circle for styling purposes */}

      <section bg-white>
        <h1
          style={{ fontFamily: "Poppins" }}
          className="flex justify-center mx-auto font-poppins text-[50px] font-[600] items-center "
        >
          Why Oversea?
        </h1>
      </section>

      {/* the redefine diagnostics section */}

      <section className="flex flex-col md:p-[125px] p-[50px] gap-[60px] lg:flex-row">
        <img
          src="image1.png"
          alt="StethoAI UI"
          class="max-w-full min-w-[250px] md:min-w-[350px] lg:min-w-[450px]"
          width="100%"
          height="auto"
        />
        <div className="mt-[50px]">
          <h1
            style={{ fontFamily: "Poppins" }}
            className="font-poppins text-[40px] font-[600] mb-4"
          >
            Ultra-Personalization, Down to the Individual
          </h1>
          <p className="text-[20px] font-[400] text-[#626E7F]">
            You need data to have the right conversations with customers. You
            get full control of the data fed into our models to hone in on the
            perfect messaging for each customer. Moreover, your data doesn't
            leave your cloud, so you can rest assured that your data is safe and
            secure.
          </p>
        </div>
      </section>

      <section className="flex flex-col md:p-[125px] p-[50px] gap-[42px] lg:flex-row">
        <div className="mt-[50px]">
          <h1
            style={{ fontFamily: "Poppins" }}
            className="font-poppins text-[40px] font-[600] mb-4"
          >
            More Personalizations = More Conversions
          </h1>
          <p className="text-[20px] font-[400] text-[#626E7F]">
            Personalization can increase open rates by a whopping 42%. Not only
            do we personalize the content of your emails, we also identify users
            that are most likely to convert into paying customers, ensuring your
            time and money spent on marketing is used efficiently.
          </p>
        </div>
        <img
          src="image3.png"
          alt="StethoAI UI"
          class="max-w-full min-w-[300px] md:min-w-[400px] lg:min-w-[500px]"
          width="100%"
          height="auto"
        />
      </section>

      {/*
      <section className="flex flex-col lg:flex-row mb-[20px] p-[25px] md:p-[50px] bg-[#3A8EF6]">
        <img width={"563px"} height={"373px"} src="LaptopLadyV2.png"></img>

        <div className="md:p-[30px] lg:p-[50px]">
          <h1
            style={{ fontFamily: "Poppins" }}
            className="text-white text-[40px] md:text-[52px] font-[600] mt-[10px]"
          >
            Experience StethoAI in Action.
          </h1>
          <p
            style={{ fontFamily: "Poppins" }}
            className="text-white text-[20px] font-[400]"
          >
            Experience the future of healthcare with our video demo. Discover
            how StethoAI seamlessly connects doctors and patients, streamlining
            diagnostics, and fostering a new standard in personalized care.
          </p>
        </div>
      </section>
  */}

      {/* section for ehr systems */}

      <section className="flex flex-col md:px-[125px]  p-[50px] gap-[42px] lg:flex-row">
        <img
          src="image4.png"
          alt="StethoAI UI"
          class="max-w-full min-w-[300px] md:min-w-[400px] lg:min-w-[500px]"
          width="100%"
          height="auto"
        />
        <div className="mt-[50px] pl-4">
          <h1
            style={{ fontFamily: "Poppins" }}
            className="font-poppins text-[40px] font-[600] mb-4"
          >
            Limited Overhead
          </h1>
          <p className="text-[20px] font-[400] text-[#626E7F]">
            Regardless of your database and email provider of choice, we will
            build a solution tailor-fit to your needs. We also handle different
            structures of data with AI, so you don't have to worry about the
            format of your data.
          </p>
        </div>
      </section>

      <section className="flex justify-center items-center flex-col">
        <h1
          style={{ fontFamily: "Poppins" }}
          className="font-poppins text-[40px] font-[600] m-5"
        >
          Demo
        </h1>

        <div className="max-w-md mx-auto bg-white rounded p-8 mb-5 shadow-md">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="companyName"
            >
              Company Name
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="companyName"
              type="text"
              placeholder="Enter Company Name"
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="companyDescription"
            >
              Company Description
            </label>
            <textarea
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="companyDescription"
              placeholder="Enter Company Description"
              onChange={(e) => setCompanyDescription(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="companyDescription"
            >
              Company Website
            </label>
            <textarea
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="companyDescription"
              placeholder="Enter Company Website"
              onChange={(e) => setCompanyWebsite(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="customerName"
            >
              Customer Name
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="customerName"
              type="text"
              placeholder="Enter Customer Name"
              onChange={(e) => setCustomerName(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="customerAge"
            >
              Customer Age
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="customerAge"
              type="text"
              placeholder="Enter Customer Age"
              onChange={(e) => setCustomerAge(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="customerAge"
            >
              Marketer Name
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="customerAge"
              type="text"
              placeholder="Enter Marketer Name"
              onChange={(e) => setMarketerName(e.target.value)}
            />
          </div>

          <div className="mb-8">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="customerInteraction"
            >
              Customer Interaction
            </label>
            <select
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="customerInteraction"
              onChange={(e) => setCustomerInteraction(e.target.value)}
            >
              <option value="never">Never</option>
              <option value="occasionally">Occasionally</option>
              <option value="regularly">Regularly</option>
              <option value="frequently">Frequently</option>
              <option value="allTheTime">All the Time</option>
            </select>
          </div>
          {customAttributes.map((customAttribute, index) => (
            <div key={index} className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor={`attribute${index}`}
              >
                Custom Attribute
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id={`attribute${index}`}
                type="text"
                placeholder="Enter Custom Attribute"
                value={customAttribute.attribute}
                onChange={handleAttributeChange(index, "attribute")}
              />
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor={`description${index}`}
              >
                Custom Description
              </label>
              <textarea
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id={`description${index}`}
                placeholder="Enter Custom Description"
                value={customAttribute.description}
                onChange={handleAttributeChange(index, "description")}
              />
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded ml-2"
                onClick={handleDeleteAttribute(index)}
              >
                Delete
              </button>
            </div>
          ))}
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleAddAttribute}
          >
            + Add Custom Attribute
          </button>
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4"
            onClick={handleGenerateEmail}
            disabled={fetchingResponse || generatedEmail}
          >
            Generate Email
          </button>
          {fetchingResponse && <GettingResponse />}

          {generatedEmail && (
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="generatedEmail"
              >
                Generated Email
              </label>
              <textarea
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="generatedEmail"
                placeholder="Generated Email will appear here"
                value={generatedEmail}
                onChange={(e) => setGeneratedEmail(e.target.value)}
                rows={generatedEmail.split("\n").length} // Set rows based on the number of lines
              />
            </div>
          )}
        </div>
      </section>

      <Element name="contact"></Element>
      <FooterComponent />
    </>
  );
}

export default LandingPage;
