import React from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";

const FooterComponent = () => {
  const {t} = useTranslation();
    return (

      <>
      <footer className="bg-[#1f1f1f] px-5 pt-5">

      <container className='flex flex-col md:flex-row justify-between md:gap-0 gap-4 items-start'>
      <div className="flex items-center">
        {/* image div */}
        <img src={`${process.env.PUBLIC_URL}/updatedlogo.png`} height={'80px'} width={'80px'}></img>
        <h1 
        style={{fontFamily: 'Satoshi'}}
        className="text-white font-Satoshi text-[32px] font-[700]">Oversea</h1>
        
      </div>
      
      
      <main className="flex flex-col sm:flex-row gap-5 justify-end">


      <div>
        
        <h1
                style={{fontFamily: 'Satoshi'}}
                className="text-[32px] font-[700] text-white"
        >About</h1>
        <ul className="space-y-4 pl-0 text-[#C9C9C9]">
          <li className="font-Satoshi"><a className='text-[#C9C9C9]' href="/about">About us</a></li>
        </ul>
      </div>

      <div>
        <h1
       style={{fontFamily: 'Satoshi'}}
        className="text-[32px] font-[700] text-white"
        >Contact</h1>
          <ul className="space-y-4 pl-0 text-[#C9C9C9]">
            <li className="font-Satoshi">sales@oversealabs.io</li>
          </ul>
      </div>


      

      </main>


      </container>

      <div>

      

      
      <div className="flex justify-center  md:justify-end gap-4 my-3">
  <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
  <img src={`${process.env.PUBLIC_URL}/icon-facebook-v-1.svg`} alt="Facebook"></img>
  </a>
  <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
    <img src={`${process.env.PUBLIC_URL}/icon-instagram.svg`} alt="Instagram"></img>
  </a>
  <a href="https://www.linkedin.com/company/" target="_blank" rel="noopener noreferrer">
    <img src={`${process.env.PUBLIC_URL}/icon-linkedin.svg`} alt="LinkedIn"></img>
  </a>
  <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
    <img src={`${process.env.PUBLIC_URL}/icon-twitter.svg`} alt="Twitter"></img>
  </a>
</div>


      <p 
      style={{fontFamily: 'Satoshi'}}
      className="text-center font-Satoshi text-white  text-[20px] font-[700]">&copy; {new Date().getFullYear()} OverseaLabs LLC. All Rights Reserved.</p>

      </div>


      </footer>
      
      
      </>
    )
}

export default FooterComponent;