import "./FoundingPage.css";
import { useEffect, useState } from "react";
import FooterComponent from "../components/FooterComponent";
import LoadingModal from "../components/LoadingModal";
const FoundingPage = () => {
    const [loading, setLoading] = useState(true);
  useEffect(() => {
    function preloadImages(imageSources) {
      try {
        const promises = imageSources.map((src) => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = src;
              img.onload = resolve;
              img.onerror = reject;
            });
          });
          return Promise.all(promises);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
     
    }
    try {
    const imageSources = ["pngvLogo.png", "Eashan.jpeg", "marketing_tech.jpg", "nevin.jpg", "victor.jpg", "erik.jpg"]
      preloadImages(imageSources).then(() => {
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        console.log(err);
      })
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  });
  if (loading) {
    return <LoadingModal/>;
  }
  return (
    <>
      <h1
        className="font-[600] text-[50px] text-center my-5"
        style={{ fontFamily: "Poppins" }}
      >
        Our Mission
      </h1>
      <section className="flex flex-col-reverse md:px-[125px]  p-[50px] gap-[42px] lg:flex-row lg:justify-center lg:items-center">
        <div className="bg-[#D9D9D9] p-[10px] md:p-[90px] rounded-[32px] shadow-lg ">
          <img
            src="updatedlogo.png"
            alt="StethoAI UI"
            class="max-w-full min-w-[300px] md:min-w-[300px] lg:min-w-[350px]"
            width="100%"
            height="auto"
          />
        </div>

        <div className="mt-[50px ">
          <p
            style={{ fontFamily: "Poppins" }}
            className="text-[19px] font-[500] text-black"
          >
            When working on previous startups in API security, healthtech, and developer tools, we noticed that as our user base grew,
            crafting personalized emails at scale became an impossible task. Yet, we notcied a high correlation between personalization of the emails
            and converting customers. Along with the expansion of Generative AI, that was the start of Oversea. Our mission is to journey with you as you grow your marketing and sales outreach by offering
            AI-marketing agents designed for conversion-rate optimization and authentic conversations with users. Everyone one of your users is important to us, 
            and we make sure they feel that way with our emails. 
          </p>
        </div>
      </section>

      <section className="bg-[#F2F7FF] rounded-md mx-3 shadow-sm">
        <main className="flex flex-col md:p-[125px] p-[50px] gap-[42px] lg:flex-row">
          <div className="mt-[50px]">
            <h1
              style={{ fontFamily: "Poppins" }}
              className="font-poppins text-[50px] text-[#031432] font-[600] mb-4"
            >
              Our Vision
            </h1>
            <p
              style={{ fontFamily: "Poppins" }}
              className="text-[19px] font-[500] text-black"
            >
              Our vision is an AI-powered B2C CRM platform. Previously, CRM platforms have been designed for B2B companies
             with limited customer volume, but we intend to bring CRM to B2C at scale. Imagine AI-agents managing your 
             customer relationships and success with the thousands to perhaps even millions of your users. Not only
             would this increase conversion rates and retention, but it harvests strong relationships with your users key 
             to longevity of your company.
            </p>
          </div>

          <div>
            <img
              src="marketing_tech.jpg"
              alt="Your Image"
              class="max-w-full min-w-[300px] md:min-w-[400px] lg:min-w-[500px] rounded-md"
              width="100%"
              height="auto"
            />
          </div>
        </main>
      </section>

      <main className="flex flex-col items-center justify-center">
        <h1
          className="font-[600] text-[55px] text-center my-5"
          style={{ fontFamily: "Poppins" }}
        >
          Founding Team
        </h1>

        <div className="flex flex-col md:flex-row gap-7 p-4 items-center justify-center">

        <div className="flex flex-col items-center justify-center">
            <img
              width={"153px"}
              height={"auto"}
              src="victor.jpg"
              className="rounded-[50%]"
            ></img>
            <h1 className="font-[600] text-[24px] text-center my-2">
              Victor Chapman
            </h1>
            <h2 className="font-[500] text-[18px] text-[#424242] text-left my-2">
              Chief Product Officer
            </h2>
            <a href="https://www.linkedin.com/in/victor-chapman-9175b2260/" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/icon-linkedin.svg`} alt="LinkedIn"></img>
            </a>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img
              width={"153px"}
              height={"auto"}
              src="eashan.jpg"
              className="rounded-[50%]"
            ></img>
            <h1 className="font-[600] text-[24px] text-center my-2">
              Eashan Vagish
            </h1>
            <h2 className="font-[500] text-[18px] text-[#424242] text-left my-2">
              Chief Executive Officer
            </h2>
            <a href="https://www.linkedin.com/in/eashan-vagish-b43405225/" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/icon-linkedin.svg`} alt="LinkedIn"></img>
            </a>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img
              width={"153px"}
              height={"auto"}
              src="erik.jpg"
              className="rounded-[50%]"
            ></img>
            <h1 className="font-[600] text-[24px] text-center my-2">
              Erik Vank
            </h1>
            <h2 className="font-[500] text-[18px] text-[#424242] text-left my-2">
              Chief Operating Officer
            </h2>
            <a href="https://www.linkedin.com/in/erik-vank/" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/icon-linkedin.svg`} alt="LinkedIn"></img>
            </a>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img
              width={"153px"}
              height={"auto"}
              src="nevin.jpg"
              className="rounded-[50%]"
            ></img>
            <h1 className="font-[600] text-[24px] text-center my-2">
                Nevin Puri
            </h1>
            <h2 className="font-[500] text-[18px] text-[#424242] text-left my-2">
              Chief Technical Officer
            </h2>
            <a href="https://www.linkedin.com/in/nevin-puri-17b5a5205/" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/icon-linkedin.svg`} alt="LinkedIn"></img>
            </a>
          </div>
        </div>
      </main>

      <FooterComponent></FooterComponent>
    </>
  );
};

export default FoundingPage;
