import React from "react";
import "./LoadingModal.css";

export default function LoadingModal({loadingText}) {
  return (
<div class="loader">
  <div class="justify-content-center jimu-primary-loading"></div>
</div>
  );
}
